'use client';

import React from 'react';
import Modal from '@/shared/ui/Modal';
import { CoverageCountriesProps } from '../../model/types';
import { CoverageCountriesList } from '../CoverageCountriesList';

function CoverageCountries({ modalControllers, ...props }: CoverageCountriesProps) {
  const title = "Coverage countries"

  if (!modalControllers) {
    return <CoverageCountriesList title={title} {...props} />
  }

  return (
    <Modal
      {...modalControllers}
      title={title}
      style={{ height: '100%' }}
      content={<CoverageCountriesList scrolledList {...props} />}
    />
  );
}

export default CoverageCountries;
