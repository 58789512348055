import React from 'react';
import { useDebounce } from 'use-debounce';
import api from '@/api';
import SearchInput from '@/shared/ui/SearchInput';
import CountryFlag from '@/shared/ui/CountryFlag';
import { Bundle } from '@/shared/types';
import * as SC from './styled';
import { CoverageCountriesProps } from '../../model/types';

type Props = Omit<CoverageCountriesProps, 'modalControllers'> & { title?: string, scrolledList?: boolean }

function CoverageCountriesList({ supportedCountries = [], disableSearch, worldwide, title, scrolledList }: Props) {
  const [filteredCountries, setFilteredCountries] = React.useState<Bundle['countries']>(supportedCountries);
  const [filterText, setFilterText] = React.useState('');
  const [debouncedFilterText] = useDebounce(filterText, 500);

  React.useEffect(() => {
    if (debouncedFilterText && !disableSearch) {
      api.profiles.getCountriesByFilterText(debouncedFilterText, 'en').then(({ data }) => {
        setFilteredCountries(
          supportedCountries.filter(({ country }) => data?.data.find((el) => el.country === country))
        );
      });
    } else {
      setFilteredCountries(supportedCountries);
    }
  }, [debouncedFilterText, disableSearch, supportedCountries, worldwide]);

  return (
    <SC.Wrapper $maxheight={Boolean(scrolledList)}>
      <SC.Header $spacebetween={Boolean(title)}>
        {title && <SC.Title>{title}</SC.Title>}
        {!disableSearch && (
          <SearchInput
            placeholder="Enter your country"
            onChange={(e) => {
              setFilterText(e.currentTarget.value.replaceAll(/[/|\\]/g, ''));
            }}
            value={filterText}
          />
        )}
      </SC.Header>
      {!filteredCountries.length && (
        <SC.NoMatchesText>Unfortunately, your search “{debouncedFilterText}” didn’t match any country.</SC.NoMatchesText>
      )}
      <SC.CountriesWrapper $scrolledlist={Boolean(scrolledList)}>
        {filteredCountries.map(({ country, isoName2, mtx, tc }) => (
          <SC.CoverageCountry key={country}>
            <SC.CoverageCountryName>
              <div>
                <CountryFlag width={42} height={42} name={isoName2} />
              </div>
              {country}
            </SC.CoverageCountryName>
            {Boolean(worldwide) && <SC.CoverageCountryPrice>${tc || mtx}/Mb</SC.CoverageCountryPrice>}
          </SC.CoverageCountry>
        ))}
      </SC.CountriesWrapper>
    </SC.Wrapper>
  );
}

export { CoverageCountriesList } 