import styled, { css } from 'styled-components';

export const CoverageCountryPrice = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: ${(props) => props.theme.secondary.text};
`;

export const Header = styled.div<{ $spacebetween: boolean }>`
  margin: 20px;

  ${(props) => (props.$spacebetween && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
  `)}
`

export const Title = styled.p`
  margin: 0 20px 0 0;
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.theme.primary.text};
`

export const CountriesWrapper = styled.div<{ $scrolledlist: boolean }>`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  ${(props) => (props.$scrolledlist && css`
    overflow: hidden scroll;
    height: 100%;
    padding: 0 20px 20px;
  `)}
`

export const CoverageCountryName = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.primary.text};

  > div {
    flex: 0 0 42px;
    width: 42px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);

    img {
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      outline: none;
      border: none;
      text-decoration: none;
    }
  }
`;

export const CoverageCountry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const Wrapper = styled.div<{ $maxheight: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${(props) => (props.$maxheight && css`
    height: 100%;
  `)}
`;

export const NoMatchesText = styled.div`
  padding: 0 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${(props) => props.theme.secondary.text};
`;

