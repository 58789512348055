'use client'

import Image from 'next/image';
import { useTheme } from '@/context/ThemeContext';

function BonusImage() {
  const { isDarkTheme } = useTheme();

  return <Image width={100} height={100} src={`/assets/100for80_${isDarkTheme ? 'dark' : 'light'}.webp`} alt='phone image' />
}

export default BonusImage;