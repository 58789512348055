'use client'

import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    justify-content: initial;
  }
`;

export const MainContentWrapper = styled.div`
  margin: 100px 0;
  flex-basis: 55%;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 0;
    padding: 0 16px;
    flex-basis: auto;
    z-index: auto;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-basis: 45%;
  z-index: 0;

  img {
    position: absolute;
    left: -20%;
    top: 0;
    width: 760px;
    height: 760px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
      width: 500px;
      height: 500px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-basis: auto;
    z-index: auto;
    height: 152vw;
    overflow: hidden;

    img {
      left: 50%;
      top: auto;
      width: 152%;
      height: auto;
      transform: translateX(-50%);
    }
  }
`;