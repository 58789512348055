'use client';

import styled from "styled-components";
import { Container } from "@/shared/ui/styled";

export const ContentWrapper = styled(Container)`
  flex-grow: 1;
`

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`