"use client"

import styled from "styled-components"

export const Wrapper = styled.div`

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const SubTitleWrapper = styled.div`
  width: max-content;
  position: relative;
  background: ${(props) => props.theme.primary.htmlBg};
  border-radius: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: 20px;
  }

  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px; 
    padding: 2px; 
    background: ${(props) => props.theme.common.gradient}; 
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude; 
  }
`

export const SubTitle = styled.div`
  width: max-content;
  padding: 5px 10px;
  background-image: ${(props) => props.theme.common.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
`

export const Title = styled.h1`
  font-size: 66px;
  color: ${(props) => props.theme.primary.text};
  font-weight: 700;
  margin: 5px 0 25px;
  line-height: 1;
  letter-spacing: -1.9px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    text-align: center;
    font-size: 40px;
    margin-top: 15px;
  }
`

export const GradientText = styled.div`
  display: inline-flex;
  padding: 20px 25px;
  width: max-content;
  transform: rotate(-2.5deg);
  background: ${(props) => props.theme.common.gradient};
  border-radius: 25px;
  border: 5px solid ${(props) => props.theme.primary.revertText};
  color: ${(props) => props.theme.common.white};
  line-height: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 5px 10px;
    border-radius: 15px;
  }
`

export const Description = styled.p`
  margin: 0;
  color: ${(props) => props.theme.primary.text};
  font-size: 24px;
  font-weight: 600;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    text-align: center;
    font-size: 18px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 50px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 35px 0 0;
  }

  > * {
    width: 256px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  }
`

export const Divider = styled.div`
  background: ${(props) => props.theme.primary.borderColor};
  height: 1px;
  width: 75%;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`

export const CoverageCountiesButton = styled.button`
  margin-top: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: ${(props) => props.theme.common.main};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-decoration-line: underline;
`

