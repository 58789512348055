'use client';

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { useAnalyticsContext } from "@/context/AnalyticsContext";
import { usePageContext } from "@/context/PageContextProvider";
import { CoverageCountries } from "@/features/coverage-countries";
import Button from "@/shared/ui/Button";
import { useModalControls } from "@/shared/hooks";
import { Bundle } from "@/shared/types";
import { ONE_LINK } from "@/shared/constants";
import * as SC from './styled';

function BonusContent({ bundle }: { bundle?: Bundle }) {
  const { source } = usePageContext();
  const searchParams = useSearchParams();
  const { sendSafeMixpanelEvent, sendSafeFbqEvent } = useAnalyticsContext();
  const { isOpen, openModal, closeModal } = useModalControls(false, { disableBodyScroll: true });
  const withDDL = searchParams.get('ddl') === 'true';

  useEffect(() => {
    sendSafeFbqEvent('PageView')
  }, [])

  const handleButtonClick = (type: 'purchase' | 'ddl') => () => {
    sendSafeMixpanelEvent('track', type === 'purchase' ? 'buy_in_app_button_click' : 'buy_in_app_button_click', { source })
    sendSafeFbqEvent('Lead')
  }

  return (
    <>
      <SC.Wrapper>
        <SC.SubTitleWrapper>
          <SC.SubTitle>Get More Pay Less</SC.SubTitle>
        </SC.SubTitleWrapper>
        <SC.Title>Unlock <SC.GradientText>€100</SC.GradientText> Worth of Data for Only €80!</SC.Title>
        <SC.Description>Join our global network with the WW Mobile Data Plan at just €80, and enjoy a generous €100 credit to your account. Grab this exclusively smart investment deal for your international adventures before it’s too late!</SC.Description>
        <SC.ButtonsWrapper>
          <Button
            onClick={handleButtonClick('purchase')}
            as="a"
            href='/details'
            label="Purchase"
          />
          {withDDL && (
            <Button
              onClick={handleButtonClick('ddl')}
              as="a"
              variant='outlined'
              href={process.env.NEXT_PUBLIC_DDL || ONE_LINK}
              label="Proceed in App"
            />
          )}

        </SC.ButtonsWrapper>
        <SC.Divider />
        <SC.CoverageCountiesButton onClick={openModal}>List of supported countries</SC.CoverageCountiesButton>
      </SC.Wrapper>
      {bundle && isOpen && <CoverageCountries modalControllers={{ isOpen, onClose: closeModal }} supportedCountries={bundle.countries} worldwide />}
    </>
  )
}

export default BonusContent;